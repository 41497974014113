import React from 'react'
import PropTypes from 'prop-types'

function placeFormattingDelimiters(
    input: string,
    replacements: { 'delimiter': string, 'tag': string, 'stop_further_replacements': boolean }[],
): JSX.Element {
    if (replacements.length === 0) {
        return <React.Fragment key={input}>{input}</React.Fragment>
    }

    const delimiter = replacements[0].delimiter
    const tag = replacements[0].tag
    const stop_further_replacements = replacements[0].stop_further_replacements
    const remaining_replacements = replacements.slice(1)
    let input_remainder = input
    let output_fragments: (string | JSX.Element)[] = []

    const delimiter_escaped = delimiter.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')  // courtesy of https://stackoverflow.com/a/3561711/5945775
    const delimiter_matcher = new RegExp('(.*?)' + delimiter_escaped + '(.+)' + delimiter_escaped + '(.*)')

    while (input_remainder.length > 0) {
        const matches = input_remainder.match(delimiter_matcher)
        if (matches === null) {
            break
        }

        // we should end up with an array containing four elements: the full string and on for each capturing group
        if (matches[1]) {
            output_fragments.push(
                placeFormattingDelimiters(matches[1], remaining_replacements)
            )
        }
        output_fragments.push(
            React.createElement(
                tag,
                {key: matches[2]},
                placeFormattingDelimiters(matches[2], stop_further_replacements ? [] : remaining_replacements)
            )
        )
        input_remainder = matches[3]
    }

    if (input_remainder) {
        output_fragments.push(
            placeFormattingDelimiters(input_remainder, remaining_replacements)
        )
    }

    return <React.Fragment key={input}>
        {output_fragments}
    </React.Fragment>
}

function applyMarkdownFormattingDelimiters(input: string): JSX.Element {
    const replacements: { 'delimiter': string, 'tag': string, 'stop_further_replacements': boolean }[] = [
        // TODO: respect <code> tags, see /en-GB/blt9c9ec783341061c1/blte5f81a9fe4a2f2a8/bltc31e043d4dc9a783
        {delimiter: '`', tag: 'code', stop_further_replacements: true},
        {delimiter: '**', tag: 'strong', stop_further_replacements: false},
        {delimiter: '__', tag: 'strong', stop_further_replacements: false},
        {delimiter: '*', tag: 'em', stop_further_replacements: false},
        {delimiter: '_', tag: 'em', stop_further_replacements: false},
    ]
    return placeFormattingDelimiters(input, replacements)
}

function buildParagraph(lines: string[]): JSX.Element {
    if (lines.length <= 0) {
        throw new RangeError('A new paragraph has to consist of at least one line.')
    }

    let paragraph_content: JSX.Element[] = []
    for (let i = 0; i < lines.length - 1; i++) {
        paragraph_content.push(
            <React.Fragment key={i}>
                {applyMarkdownFormattingDelimiters(lines[i])}
                <br/>
            </React.Fragment>
        )
    }
    paragraph_content.push(applyMarkdownFormattingDelimiters(lines[lines.length - 1]))

    return <p key={lines.join()}>{paragraph_content}</p>
}

function buildListOrParagraph(lines: string[]): JSX.Element {
    if (lines.length <= 0) {
        throw new RangeError('A new paragraph has to consist of at least one line.')
    }

    // TODO: add support for ordered lists?
    const filter = /^\s*[-+*] (.*)$/
    if (filter.test(lines[0])) {
        let list_items: JSX.Element[] = []
        let collected_lines: string[] = []

        for (const l of lines) {
            const match = filter.exec(l)
            if (match) {
                if (collected_lines.length > 0) {
                    list_items.push(
                        <li key={collected_lines.join()}>
                            {buildParagraph(collected_lines)}
                        </li>
                    )
                    collected_lines = []
                }
                collected_lines.push(match[1])
            } else {
                collected_lines.push(l)
            }
        }

        list_items.push(
            <li key={collected_lines.join()}>
                {buildParagraph(collected_lines)}
            </li>
        )
        return <ul key={lines.join()}>
            {list_items}
        </ul>
    } else {
        return buildParagraph(lines)
    }
}

const propTypes = {
    text: PropTypes.string.isRequired,
}

// TODO: add unit tests für this component
export default function MarkdownText(props: PropTypes.InferProps<typeof propTypes>): JSX.Element {
    const string_fragments = props.text.split('\n')
    let output_elements: JSX.Element[] = []
    let key = ''
    let collected_lines: string[] = []  // this helps us group paragraphs but also helps in differentiating headlines
    for (let i = 0; i < string_fragments.length; i++) {
        let fragment = string_fragments[i].trim()
        key += fragment

        // apply the appropriate html tags per line
        if (fragment === '') {
            if (collected_lines.length > 0) {
                output_elements.push(buildListOrParagraph(collected_lines))
                collected_lines = []
            }
        } else if (/^---+$/.test(fragment)) {
            if (collected_lines.length === 1) {
                output_elements.push(<h5 key={i} className="title is-5">{collected_lines[0]}</h5>)
                collected_lines = []
            } else if (collected_lines.length === 0) {
                output_elements.push(<hr key={i}/>)
            } else {
                collected_lines.push(fragment)
            }
        } else if (/^#+ .+/.test(fragment)) {  // TODO: implement support for different sizes of headlines?
            fragment = fragment.replace(/^#+\s/, '')
            output_elements.push(
                <h6 key={fragment} className="title is-6">
                    {applyMarkdownFormattingDelimiters(fragment)}
                </h6>
            )
        } else {
            collected_lines.push(fragment)
        }
    }

    if (collected_lines.length > 0) {
        output_elements.push(buildListOrParagraph(collected_lines))
    }

    return <React.Fragment key={key}>
        {output_elements}
    </React.Fragment>
}

MarkdownText.propTypes = propTypes

