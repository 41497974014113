import PropTypes from 'prop-types'
import {Link, useParams} from 'react-router-dom'
import PageSelectorComment from './PageSelectorComment'
import {useEffect, useState} from 'react'

export const navigationLinkPropType = {
    type: PropTypes.oneOf(['link']).isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    hasContent: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,  // TODO: can we use a real type instead of the generic array? It should be either "navigationLinkPropType" or the prop type of PageSelectorComment
    isActive: PropTypes.bool.isRequired,
}

const propTypes = {
    nav_node: PropTypes.exact(navigationLinkPropType).isRequired,
    path_to_parent: PropTypes.string.isRequired,
}

export default function PageSelectorNode(props: PropTypes.InferProps<typeof propTypes>): JSX.Element {
    const [display_children, setDisplayChildren] = useState<boolean>(props.nav_node.isActive)
    const route_params = useParams()
    useEffect(() => setDisplayChildren(props.nav_node.isActive), [props.nav_node.isActive, route_params])

    let nodeContent: JSX.Element
    if (props.nav_node.hasContent) {
        // TODO: fix css error: links are displayed differently than "dropdowns", they both should look the same
        nodeContent = <Link to={props.path_to_parent + props.nav_node.id}
                            className={'button is-multiline has-text-left ' + (props.nav_node.isActive ? 'is-primary' : 'is-text')}>
            {props.nav_node.title}
        </Link>
    } else {
        nodeContent = <button className={'button is-multiline has-text-left ' + (props.nav_node.isActive ? 'is-primary' : 'is-text')}
                              onClick={() => setDisplayChildren(!display_children)}>
            <span className="icon-text">
                <span className="icon">
                    {display_children ? '⏷' : '⏵'}&nbsp;
                </span>
                {props.nav_node.title}
            </span>
        </button>
    }

    if (props.nav_node.children.length > 0 && display_children) {
        nodeContent = <>
            {nodeContent}
            <ul className="menu-list">
                {
                    props.nav_node.children.map((node) => {
                        if (node.type === 'text') {
                            return <PageSelectorComment key={node.content.key} content={node.content}/>
                        } else if (node.type === 'link') {
                            return <PageSelectorNode key={node.id} nav_node={node}
                                                     path_to_parent={props.path_to_parent + props.nav_node.id + '/'}/>
                        } else {
                            throw new RangeError('unrecognized node type encountered')
                        }
                    })
                }
            </ul>
        </>
    }

    return <li>
        {nodeContent}
    </li>

}

PageSelectorNode.propTypes = propTypes