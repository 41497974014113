import DocsTreeNodeI from './DocsTreeNodeI'

export default class ClassDescriptionNode implements DocsTreeNodeI {
    private id: string
    private title: string
    private content: JSX.Element
    private children: DocsTreeNodeI[]

    constructor(id: string, title: string, content: JSX.Element, children: DocsTreeNodeI[]) {
        this.id = id
        this.title = title
        this.content = content
        this.children = children
    }

    getId(): string {
        return this.id
    }

    getTitle(): string {
        return this.title
    }

    getContent(): JSX.Element {
        return this.content
    }

    getChildren(): DocsTreeNodeI[] {
        return this.children
    }

    getChildNodes(): DocsTreeNodeI[] {
        return this.children
    }
}