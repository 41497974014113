import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {srcery as codeStyle} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import PropTypes from 'prop-types'

const propTypes = {
    code: PropTypes.string.isRequired,
    comments: PropTypes.arrayOf(
        PropTypes.shape({
            comment: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
}

export default function CodeExample(props: PropTypes.InferProps<typeof propTypes>): JSX.Element {
    let code = props.code

    for (let i = 0; i < props.comments.length; i++) {
        code = code.replaceAll(`# Comment${i+1}`, '# ' + props.comments[i]['comment'])
    }

    return <div className="block">
        <SyntaxHighlighter language="python" style={codeStyle} showLineNumbers>
            {code}
        </SyntaxHighlighter>
    </div>
}

CodeExample.propTypes = propTypes
