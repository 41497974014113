import PropTypes from 'prop-types'

const propType = {
    content: PropTypes.element.isRequired,
}

export default function PageSelectorComment(props: PropTypes.InferProps<typeof propType>) {
    return <li>{props.content}</li>
}

PageSelectorComment.propTypes = propType