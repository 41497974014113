import {Navigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import DocsService from '../services/DocsService'

async function getBestLocale() {
    // test for exact matches
    for (const lang of navigator.languages) {
        if ((await DocsService.getAvailableLanguages()).indexOf(lang) !== -1) {
            return lang
        }
    }

    // test for rough matches (e.g. de matching for de-DE)
    for (const lang of navigator.languages) {
        const found_match = (await DocsService.getAvailableLanguages()).find(value => value.startsWith(lang))
        if (found_match !== undefined) {
            return found_match
        }
    }

    // as a last resort just use english
    return 'en-GB'
}

export default function NavigateToInitialPath(): JSX.Element {
    const route_params = useParams()
    let locale: string | undefined = undefined
    if ('locale' in route_params) {
        locale = route_params['locale']
    }

    const [content, setContent] = useState<JSX.Element>(
        <div className="container">
            <p className="has-text-centered">The page is loading.</p>
            <progress className="progress is-primary"></progress>
        </div>
    )

    useEffect(() => {
        if (locale) {
            DocsService.getInitialPath(locale).then(path => {
                setContent(<Navigate to={'/' + locale + '/' + path} replace={true}/>)
            })
        } else {
            getBestLocale().then(locale => {
                DocsService.getInitialPath(locale).then(path => {
                    setContent(<Navigate to={'/' + locale + '/' + path} replace={true}/>)
                })
            })
        }
    }, [route_params])

    return content
}