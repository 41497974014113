import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    url: PropTypes.string.isRequired,
}

export default function ImagePlaceholder(props: PropTypes.InferProps<typeof propTypes>): JSX.Element {
    return <img key={props.url} src={process.env.PUBLIC_URL + '/logo192.png'}
                title={'Images in the documentation can not be loaded at the moment. Original URL: ' + props.url}
                alt=""
    />
}

ImagePlaceholder.propTypes = propTypes