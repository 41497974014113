import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import DocsService from '../services/DocsService'

export default function LocaleSelector() {
    const [availableLocales, setAvailableLocales] = useState<string[]>([])

    useEffect(() => {
        DocsService.getAvailableLanguages().then(languages => setAvailableLocales(languages))
    }, [])  // this only needs to run once, this means a constant here is perfect

    return <>
        <p className="menu-label">
            Translations
        </p>
        <ul className="menu-list">
            {availableLocales.map(
                (lang) => <li key={lang}><Link to={'/' + lang}>{lang}</Link></li>
            )}
        </ul>
    </>
}