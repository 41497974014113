import DocsTree from '../docs_tree/DocsTree'
import {NavigationLink, NavigationTree} from '../docs_tree/types'

export default class DocsService {
    private static locales_buffer: string[] = ['cs-CZ', 'da-DK', 'de-DE', 'en-GB', 'en-US', 'es-ES', 'es-MX', 'fi-FI', 'fr-CA', 'fr-FR', 'hu-HU', 'it-IT', 'ja-JP', 'ko-KR', 'lv-LV', 'nb-NO', 'nl-NL', 'pl-PL', 'pt-PT', 'ru-RU', 'sv-SE', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CHS', 'zh-CHT']
    private static docs_trees_buffer: { [key: string]: DocsTree | Promise<DocsTree> } = {}

    private static async loadDocsTree(locale: string): Promise<DocsTree> {
        const url = process.env.PUBLIC_URL + '/docs_stores/' + locale + '.json'

        let response
        try {
            response = await fetch(url)
        } catch {
            throw new Error('The provided locale could not be fetched. This likely means that the requested locale does not exist but could also indicate server errors.')
        }

        const response_json = JSON.parse(await response.text())
        return new DocsTree(response_json['topics'])
    }

    private static async getDocsTree(locale: string): Promise<DocsTree> {
        if (DocsService.docs_trees_buffer[locale])
            return DocsService.docs_trees_buffer[locale]

        const docs_tree_promise = DocsService.loadDocsTree(locale)
        DocsService.docs_trees_buffer[locale] = docs_tree_promise
        let docs_tree
        try {
            docs_tree = await docs_tree_promise
        } catch (e) {
            delete DocsService.docs_trees_buffer[locale]
            throw e
        }
        DocsService.docs_trees_buffer[locale] = docs_tree
        return docs_tree
    }

    static async getAvailableLanguages(): Promise<string[]> {
        if (DocsService.locales_buffer)
            return DocsService.locales_buffer

        // TODO: generate the list of locales from the list of files that are available (if possible)
        throw Error('Dynamic loading of the available languages has not been implemented.')
    }

    static async getInitialPath(locale: string): Promise<string> {
        const docs_tree = await DocsService.getDocsTree(locale)
        const nav_tree = docs_tree.getNavigationTree([])

        const top_level_nodes = nav_tree.filter(value => value['type'] === 'link' && value['title'].toLowerCase() === 'python')
        if (top_level_nodes.length === 0) {
            throw new Error('Could not find a matching path to redirect to.')
        }

        let end_node: NavigationTree = top_level_nodes[0]
        let path_ids: string[] = [(end_node as NavigationLink)['id']]
        while (!(end_node as NavigationLink)['hasContent']) {
            end_node = (end_node as NavigationLink)['children'].filter(value => value['type'] === 'link')[0]
            path_ids.push((end_node as NavigationLink)['id'])
        }

        return path_ids.join('/')
    }

    static async getContentForPath(locale: string, path: string): Promise<JSX.Element> {
        const docs_tree = await DocsService.getDocsTree(locale)
        return docs_tree.getContent(path.split('/'))
    }

    static async getNavigationForPath(locale: string, path: string): Promise<NavigationTree[]> {
        const docs_tree = await DocsService.getDocsTree(locale)
        return docs_tree.getNavigationTree(path.split('/'))
    }
}