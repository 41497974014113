import NavigationNode from './NavigationNode'
import ClassDescriptionNode from './ClassDescriptionNode'
import MethodDescriptionNode from './MethodDescriptionNode'
import ArticleNode from './ArticleNode'
import DocsTreeNodeI from './DocsTreeNodeI'
import {
    ComplexNode,
    ComplexNodeArticle,
    ComplexNodeMethodDescription,
    NavigationTree,
    SimpleNode,
    SimpleNodeSimplified
} from './types'
import React from 'react'
import MarkdownText from '../components/MarkdownText'
import ImagePlaceholder from '../components/ImagePlaceholder'
import CodeExample from '../components/CodeExample'

const IGNORED_NODE_TYPES = ['index', 'code_stack_example']

/**
 * A class that represents a tree storing objects of the documentation and responsible for the transformation of the docs store to a docs tree.
 *
 * Semantics we use throughout this class:
 *   - docs store: the JSON encoded file from the LEGO app
 *   - docs tree: the data structure we build from the docs store to better be able to generate JSX element to display
 *   - complex node: a node in the docs store with the "_content_type_uid" property, also contains a name and a list of children containing the content
 *   - simple node: a node in the docs store containing only a single key (representing its type) whose value mostly consists of only an object containing the content of the node
 */
export default class DocsTree {
    private docs_tree: DocsTreeNodeI[]

    constructor(docs_store: ComplexNode[]) {
        docs_store = docs_store.filter((value) => value['uid'] !== 'bltfa77a07fd9008f7b')  // filter out the docs for word blocks
        this.docs_tree = docs_store.map((node) => this.buildDocsNode(node))
    }

    /**
     * Test a node for specific types of child nodes. This is for testing "complex nodes" (i.e. the ones with the
     * "_content_type_uid" property for specific types of direct (i.e. "simple node") children.
     *
     * @private
     */
    private checkIfComplexNodeContainsSimpleType(docs_store_node: ComplexNodeArticle, node_type: SimpleNodeSimplified['type']): boolean {
        if (!this.isComplexNode(docs_store_node)) {
            throw new SyntaxError('Can only perform test on nodes with named nodes containing the _content_type_uid property')
        }

        if (!docs_store_node.hasOwnProperty('content')) {
            throw new SyntaxError('Only complex nodes of type "Article" can contain simple nodes as children')
        }

        for (const c of (docs_store_node)['content']) {
            if (c.hasOwnProperty(node_type)) {
                return true
            }
        }

        return false
    }

    private isComplexNode(docs_store_node: ComplexNode | SimpleNode): docs_store_node is ComplexNode {
        return docs_store_node.hasOwnProperty('_content_type_uid')
    }

    /**
     * Rebuild the list of simple nodes to simplify working with it.
     */
    private simplifySimpleNodesList(nodes_list: SimpleNode[]): SimpleNodeSimplified[] {
        let simplified_nodes: SimpleNodeSimplified[] = []
        for (let node of nodes_list) {
            const keys = Object.keys(node).filter((value) => value !== '_metadata')
            // @ts-ignore
            if (keys.length !== 1) {
                console.error('encountered simple node with unexpected structure: ', node)
                throw new SyntaxError('Could not parse simple node.')
            }

            const type = keys[0]
            switch (type) {
                case 'text':
                    simplified_nodes.push({
                        type: type,
                        // @ts-ignore
                        content: node[type]['content'],
                    })
                    break
                case 'article':
                case 'python_method_description':
                case 'python_code_example':
                    simplified_nodes.push({
                        type: type,
                        // @ts-ignore
                        content: node[type]['content'][0]
                    })
                    break
                case 'image':
                    simplified_nodes.push({
                        type: type,
                        // @ts-ignore
                        content: node[type]['file']['url'],
                    })
                    break
                default:
                    if (!IGNORED_NODE_TYPES.includes(type)) {
                        console.warn('unknown node type encountered: ', node)
                    }
            }
        }

        return simplified_nodes
    }

    private buildNavigationNode(docs_store: ComplexNodeArticle): NavigationNode {
        let children: (DocsTreeNodeI | JSX.Element)[] = []
        for (let c of this.simplifySimpleNodesList(docs_store['content'])) {
            switch (c['type']) {
                case 'text':
                    children.push(
                        <MarkdownText key={c['content'] as string} text={c['content'] as string}/>
                    )
                    break
                case 'article':
                    children.push(this.buildDocsNode(c['content']))
                    break
                default:
                    if (!IGNORED_NODE_TYPES.includes(c['type'])) {
                        // we don't expect other type like method descriptions here. If a complex node contains a method
                        // description it should not be classified as a navigation node.
                        console.warn('unexpected node type encountered when building navigation node: ', c)
                    }
            }
        }

        return new NavigationNode(
            docs_store['uid'],
            docs_store['article_title'],
            children,
        )
    }

    private buildClassDescriptionNode(docs_store: ComplexNodeArticle): ClassDescriptionNode {
        let children: DocsTreeNodeI[] = []
        let content_fragments: JSX.Element[] = []
        for (let c of this.simplifySimpleNodesList(docs_store['content'])) {
            switch (c['type']) {
                case 'python_method_description':
                    children.push(this.buildDocsNode(c['content']))
                    content_fragments.push(
                        <p key={c['content']['python_method_description_title']}>
                            {/* TODO: turn this into a link, relative links don't seem to work, we may need to pass the path until here to the function to build an absolute path */}
                            {c['content']['python_method_description_title']}
                        </p>
                    )
                    break
                case 'python_code_example':
                    content_fragments.push(
                        <CodeExample key={c['content']['uid']} code={c['content']['code']} comments={c['content']['comments']}/>
                    )
                    break
                case 'text':
                    content_fragments.push(
                        <MarkdownText key={c['content']} text={c['content']}/>
                    )
                    break
                case 'article':
                    // TODO: handle article
                    break
                default:
                    if (!IGNORED_NODE_TYPES.includes(c['type'])) {
                        console.warn('unexpected node type encountered when building class description node: ', c)
                    }
            }
        }

        return new ClassDescriptionNode(
            docs_store['uid'],
            docs_store['article_title'],
            <React.Fragment key={docs_store['uid']}>{content_fragments}</React.Fragment>,
            children,
        )
    }

    private buildMethodDescriptionNode(docs_store: ComplexNodeMethodDescription): MethodDescriptionNode {
        let content: JSX.Element[] = [<MarkdownText key={docs_store['description']} text={docs_store['description']}/>]

        docs_store['code_example'].map(
            (example) => content.push(
                <CodeExample key={example['uid']} code={example['code']} comments={example['comments']}/>
            )
        )

        content.push(<h5 key="Parameters" className="title is-5">
            {docs_store['python_method_description_title'] + '('
                + docs_store['parameters'].map(
                    (value) => value['default'] === '' ? value['parameter_title'] : value['parameter_title'] + '=' + value['default']
                ).join(', ')
                + ')'}
        </h5>)
        if (docs_store['parameters'].length > 0) {
            content.push(<ul key="Parameters-list">
                {docs_store['parameters'].map((value) => <li key={value['parameter_title']}>
                    <strong>{value['parameter_title']}</strong>: {value['type']}
                    {value['values'] === '' ? '' : ' (' + value['values'] + ')'}
                    {value['default'] === '' ? '' : ' = ' + value['default']}
                    <MarkdownText key={value['description']} text={value['description']}/>
                </li>)}
            </ul>)
        }

        if (docs_store['return'].length > 0) {
            content.push(<h5 key={'Returns'} className="title is-5">↩️</h5>)
            content.push(<ul key="Returns-list">
                {docs_store['return'].map((value) => <li key={value['description']}>
                    <strong>{value['type']}</strong>
                    {value['values'] === '' ? '' : ' (' + value['values'] + ')'}
                    <MarkdownText key={value['description']} text={value['description']}/>
                </li>)}
            </ul>)
        }

        if (docs_store['errors'].length > 0) {
            content.push(<h5 key={'Errors'} className="title is-5">⛔</h5>)
            content.push(<ul key="Errors-list">
                {docs_store['errors'].map((value) => <li key={value['name']}>
                    <strong>{value['name']}</strong>
                    <MarkdownText key={value['description']} text={value['description']}/>
                </li>)}
            </ul>)
        }

        return new MethodDescriptionNode(
            docs_store['uid'],
            docs_store['python_method_description_title'],
            <React.Fragment key={docs_store['python_method_description_title']}>
                {content}
            </React.Fragment>,
        )
    }

    private buildArticleNode(docs_store: ComplexNodeArticle): ArticleNode {
        let content_fragments: JSX.Element[] = []
        for (let c of this.simplifySimpleNodesList(docs_store['content'])) {
            switch (c['type']) {
                case 'text':
                    content_fragments.push(
                        <MarkdownText key={c['content']} text={c['content']}/>
                    )
                    break
                case 'python_code_example':
                    content_fragments.push(
                        <CodeExample key={c['content']['uid']} code={c['content']['code']} comments={c['content']['comments']}/>
                    )
                    break
                case 'image':
                    content_fragments.push(
                        <ImagePlaceholder key={c['content']} url={c['content']}/>
                    )
                    break
                default:
                    if (!(c['type'] in IGNORED_NODE_TYPES)) {
                        console.warn('unexpected node type encountered when building article node: ', c)
                    }
            }
        }

        return new ArticleNode(
            docs_store['uid'],
            docs_store['article_title'],
            <React.Fragment key={docs_store['uid']}>{content_fragments}</React.Fragment>,
        )
    }

    private buildDocsNode(docs_store: ComplexNode): DocsTreeNodeI {
        if (Array.isArray(docs_store) || !this.isComplexNode(docs_store)) {
            throw new SyntaxError('A docs node can only be built from a single node from the docs store.')
        }

        if (docs_store['_content_type_uid'] === 'python_method_description') {
            // method description node
            return this.buildMethodDescriptionNode(docs_store as ComplexNodeMethodDescription)
        } else if (docs_store['_content_type_uid'] === 'help_center_article') {
            if (this.checkIfComplexNodeContainsSimpleType(docs_store, 'python_method_description')) {
                // class description node
                return this.buildClassDescriptionNode(docs_store as ComplexNodeArticle)
            } else if (this.checkIfComplexNodeContainsSimpleType(docs_store, 'article')) {  // TODO: add heuristic based on length of included strings? Do we actually need a better differentiation here?
                // TODO: how do we handle code examples and articles in the same object? should we still interpret the node as a nav node?
                // navigation node
                return this.buildNavigationNode(docs_store as ComplexNodeArticle)
            }
            // article node
            return this.buildArticleNode(docs_store as ComplexNodeArticle)
        }

        console.error('unclassifiable encountered node: ', docs_store)
        throw new SyntaxError('Could not classify an encountered node!')
    }

    private buildNavigationTree(node_or_element: DocsTreeNodeI | JSX.Element, path: string[]): NavigationTree {
        if (React.isValidElement(node_or_element)) {
            return {
                type: 'text',
                content: node_or_element,
            }
        }

        const node = node_or_element as DocsTreeNodeI
        const is_on_active_path = path.length > 0 && node.getId() === path[0]
        let children: NavigationTree[]
        if (is_on_active_path) {
            children = node.getChildren().map((node) => this.buildNavigationTree(node, path.slice(1)))
        } else if (node.getContent() === null) {
            children = node.getChildren().map((node) => this.buildNavigationTree(node, []))
        } else {
            children = []
        }

        return {
            type: 'link',
            id: node.getId(),
            title: node.getTitle(),
            isActive: is_on_active_path,
            hasContent: node.getContent() !== null,
            children: children,
        }
    }

    getNavigationTree(path: string[]): NavigationTree[] {
        let nav_items = this.docs_tree.map((node) => this.buildNavigationTree(node, path))
        return nav_items
    }

    private getNodeByPath(root_nodes: DocsTreeNodeI[], path: string[]): DocsTreeNodeI {
        if (path.length <= 0) {
            throw new SyntaxError('Provided path has length 0, it has to contain at least one id.')
        }

        for (let node of root_nodes) {
            if (node.getId() === path[0]) {
                if (path.length === 1) {
                    return node
                } else {
                    return this.getNodeByPath(node.getChildNodes(), path.slice(1))
                }
            }
        }

        throw new Error('No node found on the provided path.')
    }

    getContent(path: string[]): JSX.Element {
        try {
            const node = this.getNodeByPath(this.docs_tree, path)
            return <>
                <h1 className="title">{node.getTitle()}</h1>
                {node.getContent()}
            </>
        } catch (e) {
            console.error(e)
            return <>
                <h1 className="title">Error!</h1>
                <p>Could not find a node for path "{path.join('/')}"</p>
            </>
        }
    }
}
