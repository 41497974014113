import DocsTreeNodeI from './DocsTreeNodeI'

export default class ArticleNode implements DocsTreeNodeI {
    private id: string
    private title: string
    private content: JSX.Element

    constructor(id: string, title: string, content: JSX.Element) {
        this.id = id
        this.title = title
        this.content = content
    }

    getId(): string {
        return this.id
    }

    getTitle(): string {
        return this.title
    }

    getContent(): JSX.Element {
        return this.content
    }

    getChildren(): (DocsTreeNodeI | JSX.Element)[] {
        return []
    }

    getChildNodes(): DocsTreeNodeI[] {
        return []
    }
}