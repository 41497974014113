import PropTypes from 'prop-types'
import LocaleSelector from './LocaleSelector'
import PageSelector from './PageSelector'
import {ScrollRestoration} from 'react-router-dom'

const propTypes = {
    locale: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
}

export default function Navbar(props: PropTypes.InferProps<typeof propTypes>) {
    // TODO: replace strings "Topics" and "Translations" with emoji to get around localizing them
    return (
        <aside className="menu">
            <PageSelector locale={props.locale} path={props.path}/>
            <LocaleSelector/>
            <ScrollRestoration/>
        </aside>
    )
}

Navbar.propTypes = propTypes