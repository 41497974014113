import {useEffect, useState} from 'react'
import DocsService from '../services/DocsService'
import PropTypes from 'prop-types'
import {NavigationTree} from '../docs_tree/types'
import PageSelectorNode from './PageSelectorNode'
import PageSelectorComment from './PageSelectorComment'

const propTypes = {
    locale: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
}

export default function PageSelector(props: PropTypes.InferProps<typeof propTypes>): JSX.Element {
    const [nav_items, setNavItems] = useState<NavigationTree[]>([])

    useEffect(() => {
        // we need to restrict this as otherwise this would constantly regenerate as the functions used to build the element return new objects each time
        DocsService.getNavigationForPath(props.locale, props.path).then(items => setNavItems(items))
    }, [props.locale, props.path])

    return <>
        <p className="menu-label">Topics</p>
        <ul className="menu-list">
            {
                nav_items.map((node) => {
                    if (node.type === 'text') {
                        return <PageSelectorComment key={node.content.key} content={node.content}/>
                    } else if (node.type === 'link') {
                        return <PageSelectorNode key={node.id} nav_node={node} path_to_parent={`/${props.locale}/`}/>
                    } else {
                        throw new RangeError('unrecognized node type encountered')
                    }
                })
            }
        </ul>
    </>
}

PageSelector.propTypes = propTypes