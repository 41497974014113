import DocsService from '../services/DocsService'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

const propTypes = {
    locale: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
}

export default function DocumentationPage(props: PropTypes.InferProps<typeof propTypes>) {
    const [content, setContent] = useState<JSX.Element>()

    useEffect(() => {
        DocsService.getContentForPath(props.locale, props.path).then(c => setContent(c))
    }, [props.locale, props.path])

    return <section className="section">
        <div className="container content">
            {content}
        </div>
    </section>
}

DocumentationPage.propTypes = propTypes
