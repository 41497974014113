import DocsTreeNodeI from './DocsTreeNodeI'
import {instanceOf} from 'prop-types'
import React from 'react'

export default class NavigationNode implements DocsTreeNodeI{
    private children: (DocsTreeNodeI | JSX.Element)[]
    private id: string
    private title: string

    constructor(id: string, title: string, children: (DocsTreeNodeI | JSX.Element)[]) {
        this.id = id
        this.title = title
        this.children = children
    }

    getId(): string {
        return this.id
    }

    getTitle(): string {
        return this.title
    }

    getContent(): null {
        return null
    }

    getChildren(): (DocsTreeNodeI | JSX.Element)[] {
        return this.children
    }

    getChildNodes(): DocsTreeNodeI[] {
        return this.children.filter((value) => !React.isValidElement(value)) as DocsTreeNodeI[]
    }
}