import {createBrowserRouter, Navigate} from 'react-router-dom'
import App from './App'
import React from 'react'
import NavigateToInitialPath from './components/NavigateToInitialPath'

export const router = createBrowserRouter([
    // TODO: display errors during routing (e.g. when NavigateToInitialPath can't redirect to to an error in the generation of the initial path)
    {
        path: '/',
        element: <NavigateToInitialPath/>,
    }, {
        path: '/:locale',
        element: <NavigateToInitialPath/>,
    }, {
        path: '/:locale/:firstId/*',
        element: <App/>,
    }
])